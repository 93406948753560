<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      v-if='showSideNav'
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0 sticky top-16 z-10'
      :style='`${sidebarBgStyle}`'>
      <h2 v-if='sidebarPageTitle'
        class='text-2xl uppercase'>
        {{ sidebarPageTitle }}
      </h2>
      <ul class='text-xs sidebar-navigation-links flex flex-row lg:flex-col sticky top-24 overflow-x-auto'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='whitespace-nowrap text-sm lg:text-lg py-2 px-2 lg:pl-2 lg:pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            :class='sidebarLink.activeCssClass'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            target='_blank' 
            :href='sidebarLink.link'
            class='whitespace-nowrap text-sm lg:text-lg py-2 px-2 lg:pl-2 lg:pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'
            :class='sidebarLink.activeCssClass'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0 py-12'>
      <div class='flex justify-center'>
        <div class='max-w-3xl flex'>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/gbcc2024_stamp_info.png'>
        </div>
      </div>
      <div :style='backgroundCss'>
        <div class='relative mt-8'>
          <div v-if='isMobile'>
            <qr-scanner 
              :key='scannerKey'
              :scan-helper-message='scannerHelperText'
              @scanned-qr='scannedNewQrCode' />
          </div>
          <div v-else
            class='p-24 text-center text-lg opacity-70'>
            Please scan QR codes on your mobile device.
          </div>
        </div>
        <div class='py-4 px-2'>
          <h1 class='text-center text-xl font-semibold my-8 uppercase'>Stamps Collected</h1>
          <div class='flex flex-row flex-wrap gap-2 lg:gap-4 justify-center items-center px-4 py-4'>
            <div v-for='(stamp, index) in possibleStamps' :key='`stamp-${index}`'
              class='gbcc-event-stamp-size'>
              <div class='text-center'>
                <img 
                  class='rounded-full h-20 w-20 mx-auto'
                  :class='stampedClass(stamp)'
                  :src='stampedImageUrl(stamp)' />
                <div class='text-xs truncate text-center mt-1'>{{ stamp.sponsorName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if='showOverlay'
          class='w-screen h-screen z-30 fixed top-0 left-0'>
          <div class='w-full h-full bg-gray-900 opacity-80 z-0'>
            <!-- overlay -->
          </div>
          <div class='z-10 absolute text-white' style='top:50%; left:calc(50% - 120px); width:240px;'>
            <loading-spinner v-if='showLoadingSpinner' class='block mx-auto' />
            <div v-if='showConfirmedStamp'
              class='w-full text-center'>
              <h1 
                class='text-lg mb-4 text-white font-medium'
                style='text-shadow: 2px 2px #00000055;'>{{ confirmationMessage }}</h1>
              <button 
                @click='resetStamp'
                class='block px-8 py-3 w-full rounded-md text-white uppercase font-semibold'
                style='background-color:var(--eventMainColor)'>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrScanner              from '@/components/QrScanner.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'StampEvent',
  components: {
    LoadingSpinner,
    QrScanner,
  },
  data () {
    return {
      showLoadingSpinner: false,
      showConfirmedStamp: false,
      stamped: [],
      stampedSponsorName: '',
      scannerKey: 0,
      showSideNav: true,
    }
  },
  watch: {
    '$route.query.on_mobile_app': {
      handler: function (newVal) { 
        if (!newVal) {// show if the query param on_mobile_app does not exist
          this.showSideNav = true
        } else if (newVal !== 'true') {//  or show if string is not 'true'
          this.showSideNav = true
        } else {
          this.showSideNav = false
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    sidebarLinks () {
      return [
        {
          'link': 'PhotoEvent',
          'title': 'Photo Event',
          'actionType': 'open_internal_route'
        },
        {
          'link': 'StampEvent',
          'title': 'Stamp Tour Event',
          'actionType': 'open_internal_route'
        }
      ]
    },
    backgroundCss () {
      return `background: linear-gradient(151.68deg, ${this.colorsInRange[0]} 10.86%, ${this.colorsInRange[1]} 172.48%);`
    },
    sidebarPageTitle () {
      return ''
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone') ||
             window.navigator.userAgent.includes('iPad')
    },
    emptyStamp () {
      return {
        date: '',
        sponsorName: '',
        sponsorId: null,
      }
    },
    possibleStamps () {
      let stampedCount = this.stamped.length
      let countOfStamps = 20
      let emptyStamps = countOfStamps - stampedCount
      let arrOfEmptyStamps = Array.from({ length: emptyStamps }, () => cloneDeep(this.emptyStamp))
      return [...this.stamped, ...arrOfEmptyStamps]
    },
    scannerHelperText () { // TODO (Hana): should prob make a config
      return 'Please use the camera on your mobile to scan and collect stamps.'
    },
    showOverlay () {
      return this.showLoadingSpinner || this.showConfirmedStamp // or other stuff we wants
    },
    validationStrings () {
      return [
        'gbcc-onsite',
        'sponsorId',
      ]
    },
    confirmationMessage () {
      return `You have collected a stamp for ${this.stampedSponsorName}!`
    },
    sponsorIdFromQueryParam () {
      return this.$route.query.sponsorId
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'getStampedSponsors',
      'stampSponsor',
    ]),
    scannedNewQrCode (qrCode) {
      console.log('scannedNewQrCode:', qrCode)
      const urlSearchParams = new URLSearchParams((new URL(qrCode)).search)
      let sponsorId = urlSearchParams.get('sponsorId')
      this.checkAlreadyStamped(sponsorId)
    },
    checkAlreadyStamped (sponsorId) {
      let found = this.stamped.find(stamp => stamp.sponsorId === parseInt(sponsorId))
      if (found) {
        alert('You have already collected this stamp.')
      } else {
        this.setStampForSponsor(sponsorId)
      }
    },
    setStampForSponsor (sponsorId) {
      this.showLoadingSpinner = true
      this.stampSponsor(sponsorId).then(resp => {
        console.log('stampSponsor: ', resp)
        this.getStampedSponsors().then(stampedSponsors => {
          this.showConfirmedStamp = true
          this.showLoadingSpinner = false
          this.stamped = stampedSponsors
          let justStamped = stampedSponsors.find(stamp => stamp.sponsorId === parseInt(sponsorId))
          this.stampedSponsorName = justStamped.sponsorName
          this.scannerKey += 1
        })
      })
    },
    stampedClass (stamp) {
      if (stamp.sponsorId) {
        return 'shadow-md'
      } else {
        return 'opacity-30'
      }
    },
    stampedImageUrl (stamp) {
      if (stamp.sponsorId) {
        return 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/GBCC+2024_Stamp.png'
      } else {
        return 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/GBCC+2024_unstamped.png'
      }
    },
    resetStamp () {
      this.showConfirmedStamp = false
      this.stampedSponsorName = ''
    },
  },
  mounted () {
    this.getStampedSponsors().then(resp => {
      this.stamped = resp
      if (this.sponsorIdFromQueryParam) {
        this.checkAlreadyStamped(parseInt(this.sponsorIdFromQueryParam))
      }
    })
  },
}
</script>

<style lang='scss' scoped>
.gbcc-event-stamp-size {
  width: calc(100% * (1/4) - 0.5rem - 1px);
}

@media (min-width: 1024px) { 
  .gbcc-event-stamp-size {
    width: calc(100% * (1/5) - 1rem - 1px);
  }
}

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
